.diagnose {
  background: var(--screenDiagnosis-bgColor);
  color: var(--screenDiagnosis-textColor);

  .cameraContainer {
    position: fixed;
    top: 0;
    bottom: 20%;
    left: 0;
    right: 0;
  }

  .cameraMask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 65%;
    height: 60%;
    z-index: 1;
    margin: auto;
  }

  %blockFrame_span {
    position: absolute;
    display: block;
    border: solid 4px;
    width: 100px;
    height: 100px;
    border-radius: 20px;
    clip: rect(0, 70px, 70px, 0);
    color: white;
  }

  .blockFrame span.text_center {
    @extend %blockFrame_span;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 80%;
    font-weight: bold;
    border: none;
    clip: unset;
    top: 48%;
  }

  .blockFrame span.invertedText {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .blockFrame span.left_corner {
    @extend %blockFrame_span;
    left: 0;
    top: 0;
  }

  .blockFrame span.right_corner {
    @extend %blockFrame_span;
    right: 0;
    top: 0;
    transform: rotate(90deg);
  }

  .blockFrame span.bottom_left_corner {
    @extend %blockFrame_span;
    bottom: 0;
    left: 0;
    transform: rotate(-90deg);
  }

  .blockFrame span.bottom_right_corner {
    @extend %blockFrame_span;
    right: 0;
    bottom: 0;
    transform: rotate(180deg);
  }

  .diagnosisResult {
    display: none;
  }
}

.diagnoseCompletion {
  background-color: var(--screenDiagnosis-final-bgColor);
  min-height: 100vh;
}

.arrows {
  $arrowBuffer: 8px;
  $arrowKeyframeStart: 30%;
  $arrowJustifyMiddle: 45%;
  $arrowAnimationDuration: 2s;

  i {
    position: absolute;
    font-size: 75px;
  }

  .arrowDown {
    left: $arrowJustifyMiddle;
    animation-name: animationDown;
    animation-duration: $arrowAnimationDuration;
    animation-iteration-count: infinite;
  }

  .arrowUp {
    left: $arrowJustifyMiddle;
    animation-name: animationUp;
    animation-duration: $arrowAnimationDuration;
    animation-iteration-count: infinite;
  }

  .arrowLeft {
    top: $arrowJustifyMiddle;
    animation-name: animationLeft;
    animation-duration: $arrowAnimationDuration;
    animation-iteration-count: infinite;
  }

  .arrowRight {
    top: $arrowJustifyMiddle;
    animation-name: animationRight;
    animation-duration: $arrowAnimationDuration;
    animation-iteration-count: infinite;
  }

  @keyframes animationLeft {
    0% {
      right: 0px;
      left: $arrowKeyframeStart;
    }

    50% {
      right: 0px;
      left: $arrowBuffer;
    }

    100% {
      right: 0px;
      left: $arrowKeyframeStart;
    }
  }

  @keyframes animationRight {
    0% {
      right: $arrowKeyframeStart;
    }

    50% {
      right: $arrowBuffer;
    }

    100% {
      right: $arrowKeyframeStart;
    }
  }

  @keyframes animationUp {
    0% {
      top: $arrowBuffer;
    }

    50% {
      top: $arrowKeyframeStart;
    }

    100% {
      top: $arrowBuffer;
    }
  }

  @keyframes animationDown {
    0% {
      bottom: $arrowBuffer;
    }

    50% {
      bottom: $arrowKeyframeStart;
    }

    100% {
      bottom: $arrowBuffer;
    }
  }
}