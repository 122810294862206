.feedbackBox {
  position: fixed;
  bottom: 0;
  top: 80%;
  left: 0;
  right: 0;
  background-color: var(--white-box-bgColor);
  display: flex;
  justify-content: center;
  z-index: -1;
}
.feedbackText {
  color: var(--white-box-textColor);
  font-weight: 500;
}
.invertedText {
  h3,
  span {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }
}
.timer {
  position: relative;
  width: 100px;
  height: 100px;
}

.timer_svg {
  transform: scaleX(-1);
}

.svg_circle {
  fill: none;
  stroke: none;
}

.elapsed_circle {
  stroke-width: 7px;
  stroke: grey;
}

.background_circle {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.circleColor {
  color: var(--countdownTimer-bgcolor);
}

.timerText {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: var(--countdown-textColor);
}
