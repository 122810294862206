.resultContainer {
  color: var(--completion-textColor);
  background: var(--screenDiagnosis-final-bgColor);
  height: 90vh;

  .imageContainer {
    .image {
      height: 54vh;
      max-width: 75vw;
      display: block;
      margin: 0 auto;
      padding-top: 12px;
      min-height: 37vh;
    }

    .noImage {
      width: 48px;
      height: 48px;
      display: block;
      margin: 0 auto;
      margin-top: 40px;
    }
  }

  .btnGroup {
    .btnStyle {
      padding: 12px 32px !important;
    }
  }

  .heading {
    color: var(--completion-textColor);
    font-size: 16px;
    font-weight: 500;
  }

  .specialText {
    color: var(--completion-textColor);
    text-align: justify;
    text-justify: inter-word;
    margin: 0 auto;
    text-align-last: center;
  }

  @media screen and (min-height: 850px) {
    height: 93vh;

    .image {
      height: 60vh;
    }

    .buttonGroup {
      position: fixed;
      bottom: 15px;
      left: 15px;
      min-width: calc(100vw - 30px);
    }
  }

  .noImageContainer {
    h1 {
      padding-top: 24px;
    }

    .subHeading {
      padding-top: 8px;
    }

    ul {
      padding-top: 32px;

      li {
        text-align: left;
        padding-left: 45px;
        list-style: none;
        vertical-align: middle;
        margin-bottom: 20px;
        color: var(--result-listItemColor);
      }
    }

    .instLink {
      padding-top: 12px;
      text-decoration: none;
      font-weight: 700;
      color: var(--result-linkColor);
      margin: auto;
      display: block;
    }
  }
}