@use "sass:math";

.loadingView {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 2;
  justify-content: center;
  align-items: center;
  background: var(--loadingView-bgColor);

  &.show {
    display: flex !important;
    opacity: 1;
    transition: all 0.3s linear;
  }

  p {
    font-size: 15px;
    text-align-last: center;
  }

  .noteCls {
    position: absolute;
    top: 75%;
    background-color: gray;
    opacity: 0.5;
    padding: 16px;
    margin-left: 24px;
    margin-right: 24px;
  }

  $duration: 4s;

  .box {
    height: 1em;
    width: 1em;
    font-size: 5vmin;
    background-color: #fff;
    position: absolute;
    top: 30%;
    left: 50%;
    margin: -0.5em;
    margin-left: -1em;
    transform-origin: top right;

    animation: box-flip $duration ease-in-out infinite;

    &:nth-child(2) {
      // animation-delay: #{$duration / -2};
      animation-delay: #{math.div($duration, -2)};
    }
  }

  @keyframes box-flip {
    #{percentage(math.div(1, 6))} {
      transform: rotate(0);
    }

    #{percentage(math.div(2, 6))} {
      transform: rotate(0.5turn);
    }

    #{percentage(math.div(3, 6))} {
      transform: rotate(0.5turn);
    }

    #{percentage(math.div(4, 6))} {
      transform: rotate(0.75turn);
    }

    #{percentage(math.div(5, 6))} {
      transform: rotate(0.75turn);
    }

    #{percentage(math.div(6, 6))} {
      transform: rotate(1turn);
    }
  }

  /* ---------------------------------- */

  html,
  body {
    background: #000;
    height: 100%;
    width: 100%;
    display: flex;
  }
}