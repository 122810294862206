.video {
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: 0;
  margin: auto;
  object-fit: cover;
}

.boxArea {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}
