.smallVideo {
  display: block;
  max-height: 50vh;
  margin: 0 auto;
  border: 1px solid #ccc;
  min-height: 100%;
}
.modalTitle {
  color: black;
}
.paragraph {
  color: black;
}
.bottomButtons {
  button {
    width: 100%;
  }
}
