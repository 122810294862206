.letterContainer {
    height: 100%;
    padding: 0px 10px;

    .flexContainer {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: space-between;

        p {
            font-family: "Times New Roman", Times, serif !important;
            flex: 0 0 auto;
            width: 33.33%;
            font-size: 12vh;
            font-weight: 400;
            margin-bottom: 0px;
            line-height: unset;
            text-align: center;

            &.leftChar {
                text-align: right;
            }

            &.RightChar {
                text-align: left;
            }
        }

    }

    .foldableCase p {
        font-size: 10vh !important;
    }

    .rotatechar90 {
        transform: scale(-1, 1.7);
        -moz-transform: scale(-1, 1.7);
        -webkit-transform: scale(-1, 1.7);
        -o-transform: scale(-1, 1.7);
        -ms-transform: scale(-1, 1.7);
    }
}

.darkChars {
    animation: addDarkChars .1s linear;

}

@-webkit-keyframes addDarkChars {
    0% {
        color: black;
    }

    100% {
        color: black;
    }
}

@keyframes addDarkChars {
    0% {
        color: black;
    }

    100% {
        color: black;
    }
}

// ===================  LIGHT Chars ===================

.lightChars {
    animation: addLightChars .1s linear;

}

@-webkit-keyframes addLightChars {
    0% {
        color: #f2f2f2;
    }

    100% {
        color: #f2f2f2;
    }
}

@keyframes addLightChars {
    0% {
        color: #f2f2f2;
    }

    100% {
        color: #f2f2f2;
    }
}