.singleTestRow {
  border-top: 1px solid var(--dashboard-borderColor);
  border-bottom: 1px solid var(--dashboard-borderColor);

  .flexItem {
    display: flex;
    align-items: center;
  }

  .testIcon {
    width: 32px;
    height: 32px;

    path {
      fill: var(--iconButton-testIconColor);
    }
  }

  .testLabel {
    text-align: left;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .testButton {
    font-size: 12px !important;
    padding: 8px 24px !important;
    // min-width: auto !important;
    // min-height: auto !important;
  }

  .testCompletedCheckIcon {
    color: #039855;
    width: 32px;
    height: 32px;
  }
}