.completion {
  background-color: var(--completion-bgColor);
  color: var(--completion-textColor);
  margin-top: 40px;

  .iconButton {
    margin-bottom: 16px;
    color: var(--completion-iconColor);
    font-size: 48px;

    svg {}
  }

  h2 {
    color: var(--completion-headingColor);
  }

  p {
    text-align-last: center
  }

  .buttonGroup {
    margin-top: 40px;

    .linkBtn {
      color: var(--completion-buttonColor);
    }
  }
}