$icon-size: 96px;

.instructionContainer {
  background: var(--instruction-bgColor);
  color: var(--instruction-textColor);
  padding: 24px !important;

  .heading {
    color: var(--instruction-tutorial-heading);
  }

  .subHeading {
    color: var(--instruction-tutorial-subHeading);
    margin-bottom: 0px;

    span {
      color: #25ae4b;
      font-weight: 500;

      path {
        fill: var(--instruction-iconColor);
      }
    }
  }

  .startScreen {
    .screenIcon {
      text-align: center;
      margin-bottom: 20px;

      svg {
        font-size: 48px;

        path {
          fill: var(--instruction-iconColor);
        }
      }
    }

    .subHeading {
      b {
        color: var(--instruction-tutorial-heading);
      }
    }

    .listHeading {
      margin-top: 40px;
      margin-bottom: 18px;
      color: var(--instruction-tutorial-listHeading);
    }

    ul {
      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        svg {
          font-size: 30px;
          margin-right: 18px;
          color: var(--instruction-tutorial-listHeading);
        }
      }
    }

    .buttonContainer {
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .beginButton {
        padding: 12px 32px !important;
      }

      .skipButton {
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        margin-top: 18px;
        color: var(--instruction-textColor);
      }
    }
  }

  .instructionScreen {
    position: relative;

    .stepBystep {
      margin-bottom: 8px;
      color: var(--instruction-tutorial-stepBystep);
    }

    .instructionDetailsBox {
      min-height: 113px;

      .instructionDetails {
        transition: opacity 0.7s ease;
        opacity: 1;
        position: absolute;
      }

      .hideInstructionDetails {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.7s ease;
      }
    }

    .imageContainer {
      margin-top: 24px;

      img {
        max-width: 250px;
        border-radius: 4px;
      }
    }

    .paginationDots {
      .tutorialPaginationItem {
        min-width: 60px;
        height: 2px;
        border: 1px solid var(--instruction-tutorial-pagination-bgColor);
        background-color: var(--instruction-tutorial-pagination-bgColor);

        &.tutorialPaginationItemActive {
          border: 1px solid var(--instruction-tutorial-paginationActive-bgColor);
          background-color: var(--instruction-tutorial-paginationActive-bgColor);
        }
      }
    }

    .buttonContainer {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      .nextButton {
        padding: 12px 32px;
      }

      .backButton {
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        padding-left: 0px;
        color: var(--instruction-tutorial-backButton);
        display: inherit;
        padding-top: 12px;

        svg {
          margin-right: 24px;
          font-size: 24px;
        }

        &:disabled {
          opacity: 0.3;

          svg {
            opacity: 0.3;
          }
        }
      }
    }
  }

  .endScreen {
    .flagIcon {
      display: block;
      margin: 0 auto;
      margin-top: 16px;
      margin-bottom: 16px;

      .flagIconFill {
        fill: var(--instruction-flagIconColor);
      }
    }

    .subHeading,
    .heading {
      min-height: unset;
    }

    .buttonContainer {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .startTestButton {
        padding: 12px 32px;
      }

      .tutorialAgainButton {
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        margin-top: 20px;
        color: var(--instruction-textColor);
      }
    }
  }
}

@media only screen and (min-width: 385px) and (max-width: 420px) and (min-height: 651px) and (max-height: 720px) {
  .instructionScreen {
    .buttonContainer {
      margin-top: 12vh !important;
    }
  }
}