$icon-size: 76px;

.page {
  color: var(--pageOutFound-textColor);
  min-height: 90vh;
  background: var(--pageOutFound-bgColor);

  .container {
    text-align: center;
  }

  .fontSizeAdjust {
    font-size: 18vh;
    margin-top: 4vh;
    margin-bottom: 2vh;
    color: var(--pageOutFound-color);
  }

  h1 {
    font-size: 28px;
    line-height: 25px;
  }

  p {
    line-height: 25px;
    color: var(--pageOutFound-textColor-body);
  }

  .buttonGroup {
    margin: 30px auto;

    @media screen and (max-width: 320px) {
      margin-bottom: 10px;
    }
  }
}
