.speakerWrapper {
  padding-top: 100px !important;
  text-align: center;

  .initialScreen {
    .speakerImage {
      font-size: 90px;
      margin-top: 6vh;
      border-radius: 50%;
      background-color: var(--speakerDiagnosis-iconBgColor);
      padding: 18px 22px;
      color: var(--speakerDiagnosis-iconColor);
    }
    p {
      font-size: 20px;
      padding-top: 12px;
      color: var(--speakerDiagnosis-textColor);
    }
    .countdown {
      font-size: 90px;
      padding-top: 2vh;
      color: var(--speakerDiagnosis-countDownColor);
    }
    button {
      position: fixed;
      bottom: 20px;
      left: 15px;
      width: calc(100vw - 30px);
    }
  }

  .inputScreen {
    margin-top: 7vh;

    p {
      font-size: 20px;
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 5vh;
      color: var(--speakerDiagnosis-textColor);
    }
    .inputFields {
      background-color: var(--speakerDiagnosis-bgColor);
      position: relative;
      z-index: 2;
      padding-bottom: 23vh;

      input {
        max-width: 15%;
        padding: 3px 0;
        border-radius: 3px;
        margin: 0 12px;
        font-size: 24px;
        min-height: 40px;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid var(--speakerDiagnosis-inputBorderColor);
        color: var(--speakerDiagnosis-inputTextColor);
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    .buttonGroup {
      position: fixed;
      bottom: 0;
      margin-bottom: 10px;
      z-index: -1;

      button {
        width: calc(100vw - 30px);
      }
      .checkboxArea {
        margin-bottom: 10px;
        margin-left: 15px;
        vertical-align: middle;

        input {
          padding: 5px;
          margin-right: 5px;
        }
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .initialScreen {
      .speakerImage {
        font-size: 75px;
        margin-top: 5vh;
        padding: 15px 20px;
      }
    }
  }

  @media only screen and (min-height: 650px) {
    .inputScreen {
      .buttonGroup {
        left: 15px;
      }
    }
  }
}
