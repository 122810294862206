.dashboard {
  background: var(--dashboard-bgColor);
  color: var(--dashboard-textColor);

  .heading {
    color: var(--dashboard-heading);
  }

  .subHeading {
    color: var(--dashboard-subHeading);
  }

  .assessmentHeading {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .doneArea {

    svg {
      font-size: 12vh;
      color: var(--dashboard-doneIconColor);
    }

    p {
      color: var(--dashboard-doneTextColor);
    }
  }

  .testArray {}
}