@import "~bootstrap/scss/bootstrap";

html,
#root {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 24px;
  line-height: 32px;
  font-weight: var(--app-h1-fontWeight);
  text-align: var(--app-textAlignment);
}

h2 {
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--app-h2-fontWeight);
  text-align: var(--app-textAlignment);
}

h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--app-h3-fontWeight);
  text-align: var(--app-textAlignment);
}

h4 {
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--app-h4-fontWeight);
  text-align: var(--app-textAlignment);
}

h5 {
  font-size: 12px;
  line-height: 16px;
  font-weight: var(--app-h5-fontWeight);
  text-align: var(--app-textAlignment);
}

p {
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
  font-weight: var(--app-para-fontWeight);
  text-align: var(--app-textAlignment);
}

li {
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--app-para-fontWeight);
}

.container {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.modal-content {
  border-radius: 10px;
  margin: 0 5vw;
}