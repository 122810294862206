.micWrapper {
  text-align: center;

  i.mainIcon {
    margin-top: 100px;
    font-size: 60px;
    background-color: var(--micDiagnosis-iconBgColor);
    color: var(--micDiagnosis-iconColor);
    padding: 20px 30px;
    border-radius: 50%;
  }

  .mainHeading {
    color: var(--micDiagnosis-textColor);
    font-size: 20px;
  }

  .heading {
    color: var(--micDiagnosis-textColor);
    font-size: 18px;
    padding-top: 15px;
  }

  .mainWord {
    input {
      max-width: 15%;
      padding: 3px 0;
      border-radius: 3px;
      margin: 0 12px;
      font-size: 24px;
      min-height: 40px;
      text-align: center;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid var(--speakerDiagnosis-inputBorderColor);
      color: var(--speakerDiagnosis-inputTextColor);
    }
  }

  .countDown {
    color: var(--micDiagnosis-textColor);
    font-size: 20px;
  }

  .bottomArea {
    position: fixed;
    bottom: 25px;
    margin-top: 0px;

    button {
      width: calc(100vw - 30px);
    }
  }
}

.heartBeatAnimation {
  animation: beat 0.25s infinite alternate;
  transform-origin: center;
}
@keyframes beat {
  to {
    transform: scale(1.4);
  }
}
