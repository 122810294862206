.modalContainer {
  .imgContainer {
    .foldImg {
      margin-bottom: 24px;

      .rectStroke {
        stroke: var(--errorModal-imgRect);
      }

      path {
        stroke: var(--errorModal-imgPath);
      }
    }
  }

  h1 {
    color: var(--errorModal-title-textColor);
    font-size: 18px;
    text-align: center;
  }

  p {
    color: var(--errorModal-textColor);
    text-align: center;
    text-align-last: center;
    margin-bottom: 0px;
  }

  .modalBody {
    margin: 0 4vw;
  }
}
