.micOutputWrapper {
  margin-top: 100px;
  text-align: center;

  .waveIcon {
    svg {
      path {
        fill: var(--micOutput-waveColor);
      }
    }
  }

  .playRecording button {
    margin-top: 4vh;
    border: none;
    background-color: transparent;
    color: var(--micOutput-playRecording);
    font-size: 22px;
    text-decoration: underline;
  }

  .bottomGroup {
    position: fixed;
    bottom: 25px;
    left: 15px;

    .checkBoxArea {
      margin-bottom: 10px;
      color: var(--micDiagnosis-textColor);
      label {
        font-size: 18px;
      }
    }

    button {
      width: calc(100vw - 30px);
    }
  }
}
