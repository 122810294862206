.c2pButton {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  outline: none !important;
  display: block;
  width: auto;
  border-radius: var(--cta-border-radius) !important;
  box-shadow: var(--cta-box-shadow);
  border-color: var(--cta-default-borderColor);
  background-color: var(--cta-default-bgColor);
  color: var(--cta-default-textColor);
  padding: 12px 32px !important;

  &.btn-primary {
    background-color: var(--cta-primary-bgColor);
    color: var(--cta-primary-textColor);
    border-color: var(--cta-primary-borderColor);

    &:active,
    &:hover,
    &:focus {
      background-color: var(--cta-primary-active-bgColor) !important;
      color: var(--cta-primary-active-textColor) !important;
      border-color: var(--cta-primary-active-borderColor) !important;
      box-shadow: var(--cta-box-shadow);
    }
  }

  &.btn-outline-primary {
    background-color: var(--cta-secondary-bgColor);
    color: var(--cta-secondary-textColor);
    border-color: var(--cta-secondary-borderColor);

    &:active,
    &:hover,
    &:focus {
      background-color: var(--cta-secondary-active-bgColor) !important;
      color: var(--cta-secondary-active-textColor) !important;
      border-color: var(--cta-secondary-active-borderColor) !important;
      box-shadow: var(--cta-box-shadow);
    }
    &:active:focus {
      background-color: var(--cta-secondary-active-bgColor) !important;
      color: var(--cta-secondary-active-textColor) !important;
      border-color: var(--cta-secondary-active-borderColor) !important;
      box-shadow: var(--cta-box-shadow);
    }
  }

  &:disabled,
  &.disabled,
  &.disabled:disabled {
    background-color: var(--cta-disabled-bgColor) !important;
    color: var(--cta-disabled-textColor) !important;
    border-color: var(--cta-disabled-borderColor) !important;
    opacity: 1 !important;
  }
}
