.opaqueOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 999;
  left: 0;
  right: 0;
}

.darkOverlay {
  animation: addDarkOverlay .1s linear;

}

@-webkit-keyframes addDarkOverlay {
  0% {
    background-color: black;
  }

  100% {
    background-color: black;
  }
}

@keyframes addDarkOverlay {
  0% {
    background-color: black;
  }

  100% {
    background-color: black;
  }
}

// ===================  LIGHT OVERLAY ===================

.lightOverlay {
  animation: addLightOverlay .1s linear;

}

@-webkit-keyframes addLightOverlay {
  0% {
    background-color: #FFFFFF;
  }

  100% {
    background-color: #FFFFFF;
  }
}

@keyframes addLightOverlay {
  0% {
    background-color: #FFFFFF;
  }

  100% {
    background-color: #FFFFFF;
  }
}