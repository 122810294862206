$header-height: 60px;

.header {
  position: sticky;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  padding-left: 24px !important;
  padding-right: 24px !important;
  background: var(--header-bgColor);
  border-bottom: 1px solid var(--header-borderColor);

  .headerContainer {
    height: $header-height;

    .headerItem {
      .chevronLeft {
        display: block;
        font-size: 32px;
        color: var(--helpCallButton-textColor);
        cursor: pointer;
      }

      .logo {
        height: var(--logo-size);
      }

      .helpButton {
        color: var(--helpCallButton-textColor);
        background: var(--helpCallButton-bgColor);
        font-size: 14px;

        svg {
          font-size: 16px;
        }
      }
    }

    &.shortHeader {
      height: 40px;

      .logo {
        max-height: var(--logo-smallSize);
      }
    }
  }
}
