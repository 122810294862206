.wrapper {
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
  }

  .transition-group {
    position: relative;
  }

  section.route-section {
    width: 100%;
  }
}
