.pageLoader {
  background: var(--pageLoader-bgColor);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;

  .content {
    align-self: center;

    span {
      display: block;
      text-align: center;
      color: var(--pageLoader-textColor);
    }
    span.loaderIcon {
      margin-bottom: 25px;
      i {
        font-size: 10vh;
      }
    }
    span.loaderText {
      font-size: 4vh;
    }
    span.loaderSubText {
      font-size: 3vh;
      margin-top: 10px;
    }
  }
}
